.overview {
	display: block;
	width: 100%;
}

.contactDetails {
	display: flex;
	margin-bottom: 5px;
	flex-wrap: wrap;
}

.contactDetail {
	margin-right: 15px;
	display: flex;
	align-items: center;
}

.contactDetailIcon {
	margin-right: 10px;
	fill: var(--dark-blue);
}

.patientTitle {
	display: flex;
	align-items: center;
}

.patientTitle svg {
	margin-right: 15px;
}

.patientAge {
	color: grey;
	margin-left: 10px;
	font-size: 0.85em;
}
