.profile {
	display: flex;
	align-items: center;
	margin-left: auto;
}

.logout {
	font-size: 0.9em;
	margin: 0 0 0 10px;
	padding: 0 10px 0 0;
	height: auto;
	line-height: 20px;
}

.userName {
	margin: 0 0 0 10px;
	line-height: 20px;
}

.userInfo {
	margin: 0;
	padding-right: 10px;
}

.placeholder {
	margin-left: "auto";
	text-align: right;
}
