.title {
	margin-top: 0;
	margin-bottom: 30px;
}

.actions {
	margin-top: 15px;
	margin-bottom: 20px;
}

.link {
	display: flex;
	justify-content: flex-end;
	color: var(--light-blue);
}

.link a:hover {
	text-decoration: none;
}

.buttons div div > span {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
.loginButton {
	width: 100%;
}

.loginButton {
	text-align: center;
	display: inline-block;
}