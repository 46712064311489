.page {
	display: flex;
}

.content {
	padding: 0 25px 100px;
	box-sizing: border-box;
	flex-basis: 86%;
}

.topbar {
	margin-top: 15px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 25px;
	border-bottom: 1px solid #e0e0e0;
}

.footer {
	position: fixed;
	bottom: 0px;
	right: 10px;
}
