.notes {
	flex: 1;
	margin-top: 50px;
}

.noteEditNotice {
	color: #ababab;
	font-size: 12px;
	font-style: italic;
}

.editorAuthor {
	padding-right: 8px;
}
