.questions {
	margin-top: 50px;
}

:global(.progress-affix .ant-affix) {
	background: white;
}

.logo {
	margin-bottom: 25px;
	height: 10rem;
	width: 15rem;
	border-radius: 1rem;
}

.patientDetails p {
	margin: 5px 0;
	text-align: right;
}

.topbar {
	display: flex;
	justify-content: space-between;
}

.progress {
	text-align: center;
	font-weight: bold;
	margin-top: 5px;
	background: white;
}

.progress.fixed {
	box-shadow: 1px 2px 5px 1px #f3f3f3;
	padding: 10px 0;
	margin: 0 -25px;
}

.header {
	margin-top: 25px;
}

.procedureTitle {
	font-weight: normal;
}

@media screen and (max-width: 600px) {
	.patientDetails {
		display: none;
	}
	.topbar > img {
		display: block;
		margin: 0 auto;
		height: 15rem;
		width: 20rem;
		margin-bottom: 5rem;
		transition: width 0.2s ease;
	}
}
