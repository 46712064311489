.sidebar {
	background: #2a5aad;
	overflow: hidden;
	flex-basis: 14%;
}

.logo {
	display: block;
	margin: 15px 0;
	max-width: 185px;
}

:global(.ant-menu-item) {
	font-size: 0.9vw;
}

:global(.ant-menu-item a) {
	font-size: 0.9vw;
}

:global(.ant-menu-item .anticon) {
	margin-right: 15px;
}

:global(.ant-menu-dark .ant-menu-item > a) {
	color: rgba(255, 255, 255, 0.85);
}

:global(.ant-menu-dark) {
	background-color: #2a5aad;
}

:global(.ant-menu.ant-menu-dark .ant-menu-item-selected) {
	background-color: #1b4690;
}
