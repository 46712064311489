.reviewDot {
	background: var(--blue);
	margin-right: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	border-radius: 50%;
	font-weight: 700;
	font-size: 0.85em;
	line-height: 14px;
	width: 80px;
	height: 80px;
	padding: 5px;
	box-sizing: border-box;
	border: 3px solid #c3ccda;
}

.reviewDot.overdue {
	color: #a20909;
}

:global(.ant-timeline-item-tail) {
	left: -8px;
	border-left: 5px solid #e8e8e8;
}

:global(.ant-timeline-item-content) {
	padding-left: 45px;
	margin-bottom: 50px;
	top: -30px;
}

:global(.reviews .ant-empty) {
	margin-left: -58px;
}

.reviews {
	padding-left: 45px;
}

.title {
	margin-bottom: 5px;
	margin-left: -45px;
}

.title svg {
	margin-right: 10px;
}

.reviewTitle {
	font-size: 24px;
}

.placeholder {
	opacity: 0;
	animation: fadein 2s;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.actionButton {
	margin-right: 10px;
	cursor: pointer;
}

.actionButton:hover svg {
	fill: #2a5aad;
}

.actionButton.delete:hover svg {
	fill: #a20909;
}

.key {
	margin: 25px 0 70px -45px;
}

.keyItems {
	display: flex;
}

.keyTitle {
	margin-bottom: 5px;
}

.keyItem {
	margin-right: 20px;
}

.keyItem:before {
	content: "";
	display: inline-block;
	width: 10px;
	height: 10px;
	margin-right: 5px;
}
