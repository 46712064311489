.container {
	display: flex;
}

.panelLeft {
	width: 60%;
	padding-right: 100px;
}

.panelRight {
	width: auto;
	flex: 1;
}

.panelTopLeft {
	display: flex;
}

:global(.ant-table-tbody > tr > td) {
	font-size: 14px;
	padding: 6px 8px;
}

:global(.treatment-types-table .ant-table-tbody > tr > td) {
	padding: 8px 6px;
	font-size: 14px;
}
