.treatments {
	display: block;
	margin-top: 50px;
}

.title {
	margin-bottom: 15px;
	font-size: 1.25em;
}

.row {
	cursor: pointer;
}

.active>td {
	background: #ffb300 !important;
}
