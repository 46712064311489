.customEmailBox {
	background-color: #fafafa;
	padding: 1.5rem;
	border-radius: 0.5rem;
	width: 40rem;
	height: 10rem;
	border: 0.02rem solid #d3d3d3;
	box-shadow: 0px 4px 4px #c7c7c7;
}

.customEmailBox h4 {
	margin-bottom: 1rem;
	font-size: 1.25rem;
	color: #333;
}

.customEmailBox h5 {
	margin-top: 1rem;
	font-size: 1rem;
	color: #555;
}

.customEmailBox .ant-form-item {
	align-self: center;
}

.customEmailBox .longer-list .ant-select-item-option {
	height: 2.5rem;
	line-height: 2.5rem;
}

.defaultEmailTag {
	font-size: medium;
	padding: 0.5rem;
	border-radius: 0.3rem;
	background-color: #6c757d;

	color: #fff;
}

.attachedEmailTag {
	font-size: 1rem;
	background-color: #2a5aad;
	color: #ffff;
	border-radius: 0.3rem;
	padding: 0.5rem;
	max-width: 95%;
}

.horizontalLine {
	border: none;
	border-bottom: 0.1rem solid #ccc;

	width: 100%;
}
