/* Tables */

.ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
	background: #f1f4f7;
}

.ant-table-thead>tr>th, .ant-table-tbody>tr>td {
	padding: 8px 12px;
	font-size: 16px;
}

/* Global */

body {
	font-size: 16px;
}

p {
	margin: 8px 0;
}

/* Inputs */

.ant-input, .ant-select-selection, .ant-radio-inner {
	border-color: #888888;
}

.ant-input::-moz-placeholder {
	color: #bfbfbf;
	opacity: 1;
}

.ant-input:-ms-input-placeholder {
	color: #bfbfbf;
}

.ant-input::-webkit-input-placeholder {
	color: #bfbfbf;
}

.ant-input:placeholder-shown {
	text-overflow: ellipsis;
}

.ant-input:hover {
	border-color: #40a9ff;
	border-right-width: 1px !important;
}

.ant-input:focus {
	border-color: #40a9ff;
	border-right-width: 1px !important;
	outline: 0;
	-webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
	box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.ant-input-disabled {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
}

.ant-input-disabled:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}

.ant-input[disabled] {
	color: rgba(0, 0, 0, 0.25);
	background-color: #f5f5f5;
	cursor: not-allowed;
	opacity: 1;
}

.ant-input[disabled]:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}

textarea.ant-input {
	max-width: 100%;
	height: auto;
	min-height: 32px;
	line-height: 1.5;
	vertical-align: bottom;
	-webkit-transition: all 0.3s, height 0s;
	transition: all 0.3s, height 0s;
}

.ant-radio-group-large .ant-radio-inner {
	width: 26px;
	height: 26px;
}

.ant-radio-group-large .ant-radio-inner::after {
	top: 4px;
	left: 4px;
	width: 16px;
	height: 16px;
}

.ant-radio-group-large .ant-radio-input {
	width: 26px;
	height: 26px;
}

.ant-radio-wrapper {
	line-height: 2.5;
	display: inline-flex;
	align-items: center;
}

/* Comments */

.ant-comment-content-detail {
	white-space: pre-line;
}

/* Buttons */

.ant-btn .anticon {
	vertical-align: middle;
}

.longer-list .ant-select-dropdown-menu {
	max-height: 500px;
}
